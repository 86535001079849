import { navigate } from 'gatsby';
import * as React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Button from '../components/Button/Button';
import Carousel from '../components/Carousel/Carousel';

import * as containStyles from '../styles/contain.module.scss';

import Layout from '../layouts/Layout';

import Space from '../components/Space/Space';

import MillerDisplay from '../components/Typography/MillerDisplay/MillerDisplay';
import Helvetica from '../components/Typography/Helvetica/Helvetica';

import { resetAnswers } from '../modules/questions/questionManager';
import IconRightChevron from '../components/Icons/IconRightChevron';
import Logo from '../assets/brewin-dolphin-logo.svg';


// markup
const IndexPage = (): JSX.Element => (
    <Layout isHomepage>
        <section className={`flex ${containStyles.contain} ${containStyles.containMedium}`}>
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 md:gap-y-16">
                <div className="order-2 md:order-1 col-span-2">
                    <img src={Logo} alt="Brewin Dolphin | Financial advice and investing" className="hidden md:inline-block" width={250} />
                </div>
                <div className="order-3 md:order-2 md:space-y-12 lg:space-y-16 flex items-center">
                    <Space spacing={8}>
                        <Space spacing={4}>
                            <Space spacing={2}>
                                <MillerDisplay tag="h1" typeset="title">What type of financial creature are you?</MillerDisplay>
                                <Helvetica tag="p" typeset="paragraph">When it comes to our finances, we all have different instincts.</Helvetica>
                            </Space>

                            <Button onClick={() => {
                                resetAnswers();
                                trackCustomEvent({
                                    category: 'Quiz Start',
                                    action: 'click'
                                });
                                navigate('/quiz');
                            }}
                            >
                                <span>Start the quiz</span>
                                <IconRightChevron />
                            </Button>
                        </Space>
                        <Space spacing={2}>
                            <Helvetica tag="p" typeset="paragraph">At Brewin Dolphin we find that it helps to understand what sort of financial creature you are. Because by identifying your tendencies and biases you can better understand your own blind spots.</Helvetica>
                            <Helvetica tag="p" typeset="emphasis">Take our short quiz to learn what sort of financial creature you might be.</Helvetica>
                        </Space>
                    </Space>
                </div>
                <div className="order-1 md:order-3 flex items-center">
                    <Carousel />
                </div>
            </div>
        </section>
        <section className="flow">
            <div style={{}} />
            <div style={{}} />
            <div style={{}} />
        </section>
    </Layout>
);

export default IndexPage;
