import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './Carousel.module.scss';

const Carousel = (): JSX.Element => {
    const { allArchetype: { nodes: images } } = useStaticQuery(graphql`
    query {
      allArchetype {
          nodes{
            title
            imagePath
          }
      }
    }
  `);

    return (
        <ul className={`${styles.carousel}`}>
            {
                images.map((image) => (
                    <li key={image.imagePath}><img src={image.imagePath} alt={image.title} /></li>
                ))
            }
        </ul>
    );
};

export default Carousel;
